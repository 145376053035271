<template>
  <div>
    <div class="">
      <b-alert v-model="alert.display" :variant="alert.type" class="alert-bar shadow">
        {{ alert.message }}
      </b-alert>
    </div>
    <div
      v-show="userLoaded && user.length!=0"
      class="
        login-box
        bg-white
        border
        rounded
        border-light
        text-center
        p-3 p-md-0
      "
    >
      <h4 class="mt-4 font-weight-bold">
        ตั้งรหัสผ่าน
      </h4>
      <form
        action=""
        v-promise-btn="{ action: 'submit' }"
        @submit.prevent="submit"
        class="mb-5"
      >
        <div class="form-group row justify-content-center">
          <div class="col-sm-10 mt-2">
            <div class="text-left">
              <span>อีเมล</span>
            </div>
            <input
              type="text"
              name="email"
              :value="userLoaded ? user.email : null"
              class="
                form-control
                text-center
                border-top-0 border-left-0 border-right-0
                login-border-light
              "
              readonly
            />
          </div>
          <div class="col-sm-10 mt-2">
            <div class="text-left">
              <span>ชื่อ</span>
            </div>
            <input
              type="text"
              name="name"
              :value="userLoaded ? user.name : null"
              class="
                form-control
                text-center
                border-top-0 border-left-0 border-right-0
                login-border-light
              "
              readonly
            />
          </div>

          <div class="col-sm-10 mt-2">
            <div class="text-left">
              <span>รหัสผ่าน</span>
            </div>
            <input
              type="password"
              name="password"
              v-model="password"
              class="
                form-control
                text-center
                border-top-0 border-left-0 border-right-0
                login-border-light
              "
            />
            <div v-show="credentials.errors['password']" class="text-danger">
              {{ credentials.errors['password'] }}
            </div>
          </div>
          <div class="col-sm-10 mt-2">
            <div class="text-left">
              <span>ยืนยันรหัสผ่าน</span>
            </div>
            <input
              type="password"
              name="re-password"
              v-model="rePassword"
              class="
                form-control
                text-center
                border-top-0 border-left-0 border-right-0
                login-border-light
              "
            />
            <div v-show="credentials.errors['rePassword']" class="text-danger">
              กรุณาใส่รหัสผ่านอีกครั้ง
            </div>
            <div v-show="matchPassword == false && rePassword!=''" class="text-danger">
              รหัสผ่านไม่ตรงกัน
            </div>
          </div>
          <div class="col-sm-10 helper mt-2">
            <div class="d-flex justify-content-end">
              <button type="submit" class="btn btn-action px-3 btn-login">
                สร้างรหัสผ่าน
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex';
import Form from '../modules/form/form';
import {required, email} from 'vuelidate/lib/validators';
import {get} from 'lodash';

export default {
  name: 'user-setup',
  data() {
    return {
      matchPassword: null,
      errorMessage: null,
      password: null,
      rePassword: null,
      user: null,
      key: null,
      token: null,
      userLoaded: false,
      credentials: new Form({
        data() {
          return {
            password: '',
            rePassword: '',
            key: '',
            token: '',
          };
        },
        validations: {
          password: {
            required,
          },
          rePassword: {
            required,
          },
        },
      }),
      alert: {
        display: 0,
        type: '',
        message: ''
      }
    };
  },
  created() {
  },
  mounted() {
    this.key = this.$route.query.key;
    this.token = this.$route.query.token;
    this.pullUser()
  },
  computed: {},
  methods: {
    ...mapMutations(['showLoading', 'setCsrfToken']),
    pullUser() {
      let url = '/api/check-user-token?key=' + this.key + '&token=' + this.token;
      axios.get(url).then((response) => {
        this.user = response.data;
        this.userLoaded = true;
      }).catch((error) => {
        this.userLoaded = false;
        console.log('-------error----------');
        console.log(error);
        console.log('------error-----------');
      })
    },
    checkMatchPassword() {
      if (this.password != '' && (this.password === this.rePassword)) {
        return this.matchPassword = true;
      }
      if (this.password == '' && this.rePassword == '') {
        return null;
      }
      return this.matchPassword = false;
    },
    init(data) {
      // this.setCsrfToken(data.csrfToken);
    },
    setAlert(message, type = 'success', display = 1) {
      this.alert.display = display;
      this.alert.type = type;
      this.alert.message = message;
    },

    submit() {
      if (this.checkMatchPassword() === false) {
        return;
      }
      this.credentials.password = this.password;
      this.credentials.rePassword = this.rePassword;
      this.credentials.key = this.$route.query.key;
      this.credentials.token = this.$route.query.token;
      this.credentials
        .post('/api/user-setup')
        .then((response) => {
          if (response === 'success') {
            this.setAlert('สร้างรหัสผ่านสำเร็จ');
            setTimeout(() => {
              window.location = '/login';
            }, 1000);
          }
        })
        .catch((error) => {
          this.setAlert('เกิดข้อผิดพลาดระหว่างการตั้งรหัสผ่านใหม่', 'danger');
          console.log('-----------------');
          console.log(error);
          console.log('-----------------');
        });
    },
  },
  watch: {
    rePassword() {
      this.matchPassword = null;
    }
  }
};
</script>

<style scoped lang="scss">
.login-box {
  width: 550px;
  margin: 4% auto;
}

@media (max-width: 767px) {
  .login-box {
    width: 100%;
    margin: 4% auto;
  }
}

.btn-login {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.login-border-light {
  border-color: #d7dce4;
}

.alert-bar {
  position: fixed;
  top: 30%;
  left: 50%;
  width: 80%;
  z-index: 100;
  transform: translate(-50%, -50%);
  animation-name: alert-bar-display;
  animation-duration: 2s;
  animation-timing-function: ease-out;
}

@keyframes alert-bar-display {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
